
export default {
  name: "FixedAmountLine",
  components: {
    CheckBox: () => import("@/components/donation/CheckBox"),
  },
  props: {
    isSelected: Boolean,
    selectedDonation: String,
    level: String,
    amount: String,
    discountText: String,
  },
};
